import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`V1.0`}</p>
    </blockquote>
    <h2>{`Why Customer Data Platform?`}</h2>
    <p>{`Businesses today are awash in more data than ever before. There's transactional data, demographic data, and virtually infinite amounts of behavioral data. Add it all up and you've got data from anonymous ad impressions to known customer purchases, all the way through to product usage and customer service. Customer data is a superset of all this data together.`}</p>
    <p>{`Typically this data is stored in silos, whether organizational or technological, making it very difficult for companies to provide consistent customer experiences across various channels and consumer devices.`}</p>
    <p>{`With the creation of a customer data platform (CDP) data is collected, classified and analyzed from all platforms. By centralizing customer data (all information about a visitor) from online and offline channels, CDPs can offer marketing teams a 360-degree view of their customers at all stages of a journey. `}</p>
    <p>{`Marketing teams can use this unique view of the customer to understand which tactics are most effective or to personalize things like indirect email campaign messages. Also, management can use this unique insight to understand the overall cost of acquisition and lifetime value of each customer.`}</p>
    <p><strong parentName="p">{`But, isn't CDP the same thing as a CRM?`}</strong></p>
    <p>{`No. Although CRMs and CDPs collect customer data, the main difference between them is that CRMs organize and manage customer-facing interactions with your team, while CDPs collect data about customer behavior towards your product or service.`}</p>
    <p>{`CRMs are intended to track interactions with potential and existing customers and automate the entire sales process for business development teams. They are a great tool for sales and marketing teams, especially if someone wants to get customer information quickly.`}</p>
    <p>{`Note: CRMs are not designed to handle large amounts of data from multiple sources. `}</p>
    <p><strong parentName="p">{`But, isn't CDP the same thing as a DMP?`}</strong></p>
    <p>{`While CRMs are unable to capture and analyze data from people you don't know, DMPs can collect unstructured data across a range of sources and means. CDPs are distinguished from DMPs by the persistence of customer profiles. DMPs aggregate information from multiple data points, just like CDPs. They use this data to organize segments and build audiences for targeted advertising campaigns. These audience segments, however, are not persistent profiles. Because DMPs are cookie-based, the data collected lasts as long as a cookie - a few days.`}</p>
    <p>{`CDPs contain data that has no expiry date. This data can continue to grow, enriching a customer's profile over their lifetime and keeping it persistent. This is what it means to have a persistent customer profile.`}</p>
    <p>{`Is consensual in the market, with the death of third party cookies announced, and this being the main source of data for a DMP, this concept will fall into disuse.`}</p>
    <h3>{`Purpose`}</h3>
    <p>{`Contrary to what it may seem, CDPs are not just a way to store data. They manage a large quantity of data, but serve multiple purposes: `}</p>
    <ul>
      <li parentName="ul">{`Organizing all your data`}</li>
      <li parentName="ul">{`Bring agility to collect data from everywhere and use it anywhere`}</li>
      <li parentName="ul">{`Easier, smarter data protection`}</li>
      <li parentName="ul">{`Improving the customer experience`}</li>
      <li parentName="ul">{`Reaching out to new segments`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "../../images/internal-documents/cdp-image001.png",
        "alt": null
      }}></img></p>
    <p>{`Therefore, a CDP can incorporate several purposes and allow data aggregation, analysis, campaign management, automation and others     `}</p>
    <p>{`A CDP manage all types of data:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Contact information and identity data (PII)`}</strong>{` - e.g. name (first, middle and last), current physical address, email address, date of birth, contact information (mobile number, landline number), CC number, tax number, ...`}</li>
      <li parentName="ul"><strong parentName="li">{`Qualitative data, Engagement`}</strong>{` - e.g. social media engagement rates, video view count, campaign conversion rates, website traffic, email open rates and click through rates, ...`}</li>
      <li parentName="ul"><strong parentName="li">{`Qualitative, Behavioral data`}</strong>{` - e.g. duration for which a customer stays on a webpage, how often a customer orders a product, what time of day a customer is most likely to visit a website, average customer spend, which days of the week a customer is least active, ... `}</li>
      <li parentName="ul"><strong parentName="li">{`Qualitative, Emotional data`}</strong>{` - e.g. customer satisfaction, opinions about a particular product or service, customer feelings, attitude towards physical traits, customer trouble spots, likes and dislikes of a particular product or message, ...`}</li>
    </ul>
    <h2>{`CDP at CoreMedia`}</h2>
    <h3>{`Introduction`}</h3>
    <p>{`CoreMedia has created an external system, which integrates with our solution natively, and allows us to to aggregate and consolidate data centralized on the concept of customer, so as to be able to give its customers a holistic view of each one, and use this curated information to be able to leverage other services/systems.`}</p>
    <p>{`In light of this new system, a customer is a concept that goes beyond what a CoreMedia visitor is (which encompasses "only" all customers who have visited a site tagged CoreMedia) and aims to represent a person in the real world who interacts with a CoreMedia customer through various channels, even if those channels are not controlled by CoreMedia.`}</p>
    <p>{`One of the key points of the CDP is that the sources of this data is not only internal (only data generated by CoreMedia services) but also from third parties, leveraging a set of integrations with external data sources that thus allow enriching the profiles built.`}</p>
    <p>{`On the other hand, and given that a CDP not only serves as an information repository, it has functionality that allows the creation of segments (audiences), whether static or dynamic in real time and respective synchronization with the different interested platforms. `}</p>
    <p>{`Our Customer Data Platform provides:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`identity resolution`}</strong>{`, understanding how to add new data points to existing profiles`}</li>
      <li parentName="ul"><strong parentName="li">{`customer stitching`}</strong>{`, bringing together existing customer data when it is detected that they are in fact the same `}</li>
      <li parentName="ul"><strong parentName="li">{`easy integration,`}</strong>{` integrate third-party data to enrich existing data generated or collected by CoreMedia`}</li>
      <li parentName="ul"><strong parentName="li">{`manage audience/segments,`}</strong>{` have a tool that allows the creation and management of customer segments (audiences)`}</li>
    </ul>
    <p>{`And use the following terminology`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Customer`}</strong>{` - It will be an entity that intends to represent/characterize all the people that in a generic way interact with the platform through its several contact points (web, voice, email, sms).`}<br />{`A customer is composed buy:`}
        <ul parentName="li">
          <li parentName="ul">{`Unique identifier: UUID (AKA CustomerID)`}</li>
          <li parentName="ul">{`Secondary identifiers: Email, Phone, Push_id, CoreMedia Visitor_id, Cookie(s), GClid, FBID, MatchId, DMPS internal IDs, etc`}</li>
          <li parentName="ul">{`Attributes: relevant information associated to the customer`}</li>
          <li parentName="ul">{`Segments: sets of customers to which it belongs`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Attributes`}</strong>{` - Set of properties (finite, 100 properties) associated to the Customer.`}</li>
      <li parentName="ul"><strong parentName="li">{`Segments`}</strong>{` - Set of tags (infinite) associated to a customer, representing a group of customers to which it belongs (example: all male customers living in Oporto). They may be imported directly from external CDP sources or assigned by the segmentation engine.`}</li>
      <li parentName="ul"><strong parentName="li">{`Types of Segmentation`}</strong>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Static/Manual`}</strong>{` - created based on a determined set of rules applied on top of the customers' attributes, a group of customer was tagged one time. Additions and removals are manual `}</li>
          <li parentName="ul"><strong parentName="li">{`Dynamic/Automatic/Adaptive`}</strong>{` - created based on a certain set of rules applied on top of the leads' attributes, a customer of customer was tagged but additions and removals are automatic. `}</li>
        </ul>
      </li>
    </ul>
    <h3>{`Architecture`}</h3>
    <p>{`When defining the technical architecture for CoreMedia CDP we defined a set of non-functional requirements we wanted to achieve:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Scalability:`}</strong>{` The main objective of a CDP is to manage all customer data information and provide a unified view of a client's customers. A CDP implementation normally starts small but adding all input sources where customer data is handled in a company can lead to thousands or millions of events a day. We designed CoreMedia CDP architecture to easily scale by using an event based architecture and ensuring all CDP components are decoupled in a way that processing can be scaled up and down module by module.`}</li>
      <li parentName="ul"><strong parentName="li">{`Cloud-first:`}</strong>{` We designed CoreMedia CDP to run on the cloud. For our first PoCs (Proof of Concepts) we have deployed all CoreMedia CDP architecture in Amazon AWS. Being cloud-first makes it easy to scale up and down the resources according to traffic and processing needs. When using public cloud, there is always a choice between using a managed service - like ElasticCache on AWS - and install it on a virtual machine and those decisions need to take into account the lock-down to specific services only available in one cloud provider. During our PoCs we used specific AWS services (such as DynamoDB or Kinesis Data Streams) but we ensured that we have alternatives (e.g. Kafka instead of Kinesis) if we want to move to other cloud provider.`}</li>
      <li parentName="ul"><strong parentName="li">{`Containerized`}</strong>{`: We use containers to run the core of CDP services. Using a container-based deployment makes it easier to scale up and down the resources CoreMedia CDP is using according to load, using a container orchestration and management service (AWS Fargate, ECS or Kubernetes).`}</li>
      <li parentName="ul"><strong parentName="li">{`Multi-tenant`}</strong>{`: CoreMedia CDP was designed to be multi-tenant meaning that a CoreMedia CDP production zone can serve different CoreMedia CDP clients ensuring all the security and data isolation between tenants and providing all the scalability mechanisms to ensure all clients have the same performance.`}</li>
      <li parentName="ul"><strong parentName="li">{`Dedicated CDP`}</strong>{`: At the same time, we designed CoreMedia CDP so we can deploy a dedicated CoreMedia CDP zone for a single client. Some companies want to have total control on their data and their infrastructure. CoreMedia CDP is ready to be installed as a dedicated instance/zone on client's cloud account - example: on client's AWS account - and serving only that client data.`}</li>
    </ul>
    <h4>{`Services/Modules`}</h4>
    <p><strong parentName="p">{`Core Module`}</strong>{`, responsible to all CDP logic:`}</p>
    <pre><code parentName="pre" {...{}}>{`*  Identity Resolution, service responsible for researching and identifying customers
*  Create, service responsible for creating new customers;
*  Update, service responsible for updating customer information;
*  Merge, service responsible for merging customers into one;
*  Integrity Check, service responsible for guaranteeing that a customer's information is always complete and coherent before being forwarded to the outbound service. 
`}</code></pre>
    <p><strong parentName="p">{`Outbound/Inbound Service`}</strong>{`, responsible for receiving and sending information to and from other systems.
`}<strong parentName="p">{`Segmentation Service`}</strong>{`, responsible to create audience and add/remove segments from customers based on rules pre-defined.
`}<strong parentName="p">{`CDP App`}</strong>{`, app responsible for CDP configurations (e.g. Identifiers and attributes, integration, rules)     `}</p>
    <p><img parentName="p" {...{
        "src": "../../images/internal-documents/cdp-image002.png",
        "alt": null
      }}></img></p>
    <h3>{`Analytics and reporting`}</h3>
    <p>{`To support operational and business analysis, data manager has a set of statistical dashboards to help/monitor his business. `}</p>
    <p>{`The metrics available are: `}</p>
    <ul>
      <li parentName="ul">{`number of customers created/updated per period (e.g: week, month);`}</li>
      <li parentName="ul">{`number of customers merged;`}</li>
      <li parentName="ul">{`number of customer created/updated by parameters filters (e.g: source, channel,...)`}</li>
      <li parentName="ul">{`number of segments created/updated per period (e.g: week, month);`}</li>
      <li parentName="ul">{`number of customer per segment;`}</li>
      <li parentName="ul">{`number of additions/removals per segment;`}</li>
    </ul>
    <h3>{`CoreMedia Platform integration with CoreMedia CDP`}</h3>
    <p>{`CDP is a CoreMedia app, like the CoreMedia apps, it extends the platform's basic service, guaranteeing more efficient management of visitors, segments and audiences.`}</p>
    <p>{`Therefore, by enabling the CDP on the CoreMedia platform automatically guarantee that:`}</p>
    <ul>
      <li parentName="ul">{`visitors/contacts and segments are sent and synchronized with the CDP;`}</li>
      <li parentName="ul">{`"customer" concept is supported (all the information of the same online visitor, offline visitor and various contacts of different channels).`}</li>
    </ul>
    <p>{`In addition, CDP and its merge functionality allows CoreMedia platform to be more optimized to:`}</p>
    <ul>
      <li parentName="ul">{`display all contacts of a customer (all contacts of a visitor and/or all visitors merged with that one);`}</li>
      <li parentName="ul">{`Show the most recent/updated customer information`}</li>
      <li parentName="ul">{`send campaigns preventing the repetition of the same campaign to the same visitor;`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "../../images/internal-documents/cdp-image003.png",
        "alt": null
      }}></img></p>
    <h3>{`Use cases`}</h3>
    <h4>{`New visitor at CoreMedia`}</h4>
    <p>{`When a new visitor is created on CoreMedia, this information is sent to CDP. CDP collects, verify if customer already exists or if it is possible to be merged, calculate audience and notify CoreMedia again, if needed. Sent information to external systems.    `}</p>
    <p><img parentName="p" {...{
        "src": "../../images/internal-documents/cdp-image004.png",
        "alt": null
      }}></img></p>
    <h4>{`New contact at CoreMedia`}</h4>
    <p>{`When a contact start at CoreMedia, the visitor information was sent to CDP, verify and validate visitor/Customer information, verify if this visitor was merged in the past and inform CoreMedia with all visitors merged. CoreMedia retrieves all contacts from this visitors and present it on ByCard.`}</p>
    <p><img parentName="p" {...{
        "src": "../../images/internal-documents/cdp-image005.png",
        "alt": null
      }}></img></p>
    <h2>{`API`}</h2>
    <p>{`CoreMedia CDP API is divided in 2 main groups:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Settings API`}</strong>{` CDP global configurations (provider groups, providers, receiver groups, receivers), register CDP clients, configure customer record, configure inbound providers (sources), configure outbound providers (sinks).`}</li>
      <li parentName="ul"><strong parentName="li">{`Inbound API`}</strong>{` configure inbound providers (sources), sending customer data.`}</li>
      <li parentName="ul"><strong parentName="li">{`Outbound API`}</strong>{` configure outbound receivers (sinks).`}</li>
    </ul>
    <h3>{`Settings: Get Customer Record Settings`}</h3>
    <p>{`Get current settings for the customer record structure for a CDP client.`}</p>
    <h4>{`Resource URL and Method`}</h4>
    <p>{`https://{CDP_URL} /settings/{CLIENT_ID}/customers`}</p>
    <p><strong parentName="p">{`Method:`}</strong>{` GET`}</p>
    <h4>{`Resource Information`}</h4>
    <p><strong parentName="p">{`Response format`}</strong>{`: JSON`}</p>
    <p><strong parentName="p">{`Requires Authentication`}</strong>{`: Yes`}</p>
    <h4>{`Response - JSON body`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`identifiers - `}</strong>{`list of identifiers for the customer record. For each identifier:`}</li>
      <li parentName="ul">{`name: name of the identifier attribute`}</li>
      <li parentName="ul">{`type`}<strong parentName="li">{`: `}</strong>{`identifier attribute type. Options;  STRING, INTEGER, DATE, FLOAT, BOOLEAN`}</li>
      <li parentName="ul">{`multivalue: (true | false). If this identifier can have multiple values.`}</li>
      <li parentName="ul"><strong parentName="li">{`attributes - `}</strong>{`list of attributes part of the customer record. For each attribute:`}</li>
      <li parentName="ul">{`name: name of the identifier attribute`}</li>
      <li parentName="ul">{`type:** **identifier attribute type. Options;  STRING, INTEGER, DATE, FLOAT, BOOLEAN`}</li>
      <li parentName="ul">{`multivalue: (true | false). If this attribute can have multiple values.`}</li>
    </ul>
    <h4>{`Response`}</h4>
    <p><strong parentName="p">{`Status Code`}</strong>{`: 200 OK`}</p>
    <h4>{`Example Response`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "identifiers": [
        {
            "name": "nif",
            "type": "STRING",
            "multiValue": false
        },
        {
            "name": “passportNumber",
            "type": "INTEGER",
            "multiValue": false
        }
    ],
    "attributes": [
        {
            "name": “firstName",
            "type": "STRING",
            "multiValue": false
        },
        {
            "name": "lastName",
            "type": "STRING",
            "multiValue": false
        },
        {
            "name": "phone",
            "type": "STRING",
            "multiValue": true
        }
    ]
}
`}</code></pre>
    <p><strong parentName="p">{`Response`}</strong></p>
    <h3>{`Settings: Create/Update Customer Record Settings`}</h3>
    <p>{`Creates or updates the customer record structure - identifiers and attributes.`}</p>
    <h4>{`Resource URL and Method`}</h4>
    <p>{`https://{CDP_URL} /settings/{CLIENT_ID}/customers`}</p>
    <p><strong parentName="p">{`Method:`}</strong>{` PUT`}</p>
    <h4>{`Resource Information`}</h4>
    <p><strong parentName="p">{`Request Format`}</strong>{`: JSON body`}</p>
    <p><strong parentName="p">{`Response format`}</strong>{`: JSON`}</p>
    <p><strong parentName="p">{`Requires Authentication`}</strong>{`: Yes`}</p>
    <h4>{`Parameters`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`identifiers - `}</strong>{`list of identifiers for the customer record (must have at least one). For each identifier:`}
        <ul parentName="li">
          <li parentName="ul">{`name: name of the identifier attribute`}</li>
          <li parentName="ul">{`type:identifier attribute type. Options;  STRING, INTEGER, DATE, FLOAT, BOOLEAN`}</li>
          <li parentName="ul">{`multivalue: if CDP should allow having multiple values for this identifier attribute`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`attributes - `}</strong>{`list of attributes part of the customer record. For each attribute:`}
        <ul parentName="li">
          <li parentName="ul">{`name: name of the identifier attribute`}</li>
          <li parentName="ul">{`type:** **identifier attribute type. Options;  STRING, INTEGER, DATE, FLOAT, BOOLEAN`}</li>
          <li parentName="ul">{`multivalue: if CDP should allow having multiple values for this identifier attribute`}</li>
        </ul>
      </li>
    </ul>
    <h4>{`Response`}</h4>
    <p><strong parentName="p">{`Status Code`}</strong>{`: 200 OK`}</p>
    <p><strong parentName="p">{`Body`}</strong>{`: Updated customer record settings - same format as Get Customer Record Settings.`}</p>
    <h4>{`Example Request`}</h4>
    <p><strong parentName="p">{`Request`}</strong>{` `}</p>
    <pre><code parentName="pre" {...{}}>{`{
    "identifiers": [
        {
            "name": "nif",
            "type": "STRING",
            "multiValue": false
        },
        {
            "name": “passportNumber",
            "type": "INTEGER",
            "multiValue": false
        }
    ],
    "attributes": [
        {
            "name": “firstName",
            "type": "STRING",
            "multiValue": false
        },
        {
            "name": "lastName",
            "type": "STRING",
            "multiValue": false
        },
        {
            "name": "phone",
            "type": "STRING",
            "multiValue": true
        }
    ]
}
`}</code></pre>
    <h3>{`Inbound: Create/Update Provider Group`}</h3>
    <p>{`Providers Groups are used to group various inbound data providers. An example would be to create a provider group named `}<strong parentName="p">{`Facebook`}</strong>{` and then add providers to this group such as Pages_webhook, Facebook Lead Ads, ...`}</p>
    <h4>{`Resource URL and Method`}</h4>
    <p>{`https://{CDP_URL} /providergroups`}</p>
    <p><strong parentName="p">{`Method:`}</strong>{` POST          `}</p>
    <h4>{`Resource Information`}</h4>
    <p><strong parentName="p">{`Request Format`}</strong>{`: JSON body`}</p>
    <p><strong parentName="p">{`Response format`}</strong>{`: JSON`}</p>
    <p><strong parentName="p">{`Requires Authentication`}</strong>{`: Yes`}</p>
    <h4>{`Parameters           `}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`name:`}</strong>{` name of the provider group. Names are unique and doing more than 1 request for the same name will update the provider group instead of creating a new one.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`prefix`}</strong>{`: prefix for this group to be used when listing providers.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`providerList`}</strong>{`: the list of providers part of this group. Additional providers may be added later. For each provider:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`name: name of the provider`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`type`}<strong parentName="p">{`: `}</strong>{`type of the provider (ex: auth)`}</p>
          </li>
        </ul>
      </li>
    </ul>
    <h4>{`Response`}</h4>
    <p><strong parentName="p">{`Status Code`}</strong>{`: 200 OK`}</p>
    <p><strong parentName="p">{`Body`}</strong>{`: Created/Updated provider group information saved on CoreMedia CDP including the id of the provider group and for each provider the id assigned by CoreMedia CDP.`}</p>
    <h4>{`Example Request`}</h4>
    <p><strong parentName="p">{`Request`}</strong>{` `}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "name": "Facebook",
  "prefix": "fb",
  "providerList": [
    {
      "name": "pages_webhook",
      "type": "auth"
    }
  ]
}
`}</code></pre>
    <h3>{`Inbound: Delete provider group`}</h3>
    <p>{`Deletes a provider group.`}</p>
    <h4>{`Resource URL and Method`}</h4>
    <p>{`https://{CDP_URL} /providergroups/{PROVIDER_GROUP_ID}`}</p>
    <p><strong parentName="p">{`Method:`}</strong>{` DELETE       `}</p>
    <h4>{`Resource Information`}</h4>
    <p><strong parentName="p">{`Request Format`}</strong>{`: none`}</p>
    <p><strong parentName="p">{`Response format`}</strong>{`: none`}</p>
    <p><strong parentName="p">{`Requires Authentication`}</strong>{`: Yes`}</p>
    <h4>{`Response`}</h4>
    <p><strong parentName="p">{`Status Code`}</strong>{`: 200 OK`}</p>
    <h3>{`Inbound: Create/Update Provider on Provider Group`}</h3>
    <p>{`Creates or updates a provider within a provide group.`}</p>
    <h4>{`Resource URL and Method`}</h4>
    <p>{`https://{CDP_URL} /providergroups/{PROVIDER_GROUP_ID}/providers`}</p>
    <p><strong parentName="p">{`Method:`}</strong>{` POST          `}</p>
    <h4>{`Resource Information`}</h4>
    <p><strong parentName="p">{`Request Format`}</strong>{`: JSON body`}</p>
    <p><strong parentName="p">{`Response format`}</strong>{`: JSON`}</p>
    <p><strong parentName="p">{`Requires Authentication`}</strong>{`: Yes`}</p>
    <h4>{`Parameters           `}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`name: `}</strong>{`name of the provider.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`type`}</strong>{`: type of the provider. (ex: auth)`}</p>
      </li>
    </ul>
    <h4>{`Response`}</h4>
    <p><strong parentName="p">{`Status Code`}</strong>{`: 200 OK`}</p>
    <p><strong parentName="p">{`Body`}</strong>{`: Created/Updated provider information.`}</p>
    <h4>{`Example Request`}</h4>
    <p><strong parentName="p">{`Request`}</strong>{` `}</p>
    <pre><code parentName="pre" {...{}}>{`{
   "name": "pages_webhook",
   "type": "auth"
}
`}</code></pre>
    <h3>{`Inbound: Set provider for client and property mappings`}</h3>
    <p>{`For each CoreMedia CDP client, this API method is used to configure the mappings between the information obtained from the provider and the attributes in the customer record structure.`}</p>
    <h4>{`Resource URL and Method`}</h4>
    <p>{`https://{CDP_URL} /settings/inbound/{CLIENT_ID}/inbound/{PROVIDER_NAME}`}</p>
    <p><strong parentName="p">{`Method:`}</strong>{` PUT `}</p>
    <h4>{`Resource Information`}</h4>
    <p><strong parentName="p">{`Request Format`}</strong>{`: JSON body`}</p>
    <p><strong parentName="p">{`Response format`}</strong>{`: JSON`}</p>
    <p><strong parentName="p">{`Requires Authentication`}</strong>{`: Yes`}</p>
    <h4>{`Parameters           `}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`propertyMappingList`}</strong>{`: list of attributes mapping between the information that will be received by this provider and customer record structure. For each entry:`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`propertyName: the name of the attribute on provider`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`mapping: attibute name on customer record structure.`}</p>
      </li>
    </ul>
    <h4>{`Response`}</h4>
    <p><strong parentName="p">{`Status Code`}</strong>{`: 200 OK`}</p>
    <p><strong parentName="p">{`Body`}</strong>{`: Created/Updated provider mapping information including the providerID that will be used as `}<strong parentName="p">{`dataProviderKey`}</strong>{` header when sending customers information.`}</p>
    <h4>{`Example Request`}</h4>
    <p><strong parentName="p">{`Request`}</strong>{` `}</p>
    <pre><code parentName="pre" {...{}}>{`{
    "propertyMappingList": [
        {
            "propertyName": “c_nif",
            "mapping": "nif"
        },
        {
            "propertyName": “telephone_number”,
            "mapping": "phone"
        }
   ]
}
`}</code></pre>
    <h3>{`Inbound: Send customers data`}</h3>
    <p>{`This is the API entry point for customer information. For information to be considered as valid and processed by the CDP the following requirements must be met:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Provider must be configured for the client`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Properties provided must have a mapping to the customer record`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`At least it has to contain 1 identifier`}</p>
      </li>
    </ul>
    <h4>{`Resource URL and Method`}</h4>
    <p>{`https://{CDP_URL} /customers`}</p>
    <p><strong parentName="p">{`Method:`}</strong>{` POST          `}</p>
    <h4>{`Resource Information`}</h4>
    <p><strong parentName="p">{`Request Format`}</strong>{`: JSON body`}</p>
    <p><strong parentName="p">{`Request Headers:`}</strong></p>
    <ul>
      <li parentName="ul">{`dataProviderKey: ID of the provider within the client obtained when defining the property mappings.`}</li>
    </ul>
    <p><strong parentName="p">{`Response format`}</strong>{`: JSON`}</p>
    <p><strong parentName="p">{`Requires Authentication`}</strong>{`: Yes`}</p>
    <h4>{`Parameters           `}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Identifiers: `}</strong>{`list of identifiers with corresponding values.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`attributes`}</strong>{`: list of attributes with corresponding values.`}</p>
      </li>
    </ul>
    <h4>{`Response`}</h4>
    <p><strong parentName="p">{`Status Code`}</strong>{`: 200 OK`}</p>
    <h4>{`Example Request`}</h4>
    <p><strong parentName="p">{`Request`}</strong>{` `}</p>
    <pre><code parentName="pre" {...{}}>{`{
    "identifiers": {
        “c_nif": 9999999,
    },
    "attributes": {
        "fname": "John",
        "lname": “Doe”,
        “telephone_number": [
            “91111111”,
         “922222222”
        ]
    }
}
`}</code></pre>
    <h3>{`Outbound: Create/Update Receiver group`}</h3>
    <p>{`A receiver group allows to group various receivers (sinks) of customer data information.`}</p>
    <h4>{`Resource URL and Method`}</h4>
    <p>{`https://{CDP_URL} /receivergroups`}</p>
    <p><strong parentName="p">{`Method:`}</strong>{` POST`}</p>
    <h4>{`Resource Information`}</h4>
    <p><strong parentName="p">{`Request Format`}</strong>{`: JSON body`}</p>
    <p><strong parentName="p">{`Response format`}</strong>{`: JSON`}</p>
    <p><strong parentName="p">{`Requires Authentication`}</strong>{`: Yes`}</p>
    <h4>{`Parameters           `}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`name`}</strong>{`: name of customer group`}</li>
      <li parentName="ul"><strong parentName="li">{`prefix`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`receiverList: `}</strong>{`list of receivers within this receiver group. For each receiver:`}
        <ul parentName="li">
          <li parentName="ul">{`name`}</li>
          <li parentName="ul">{`type: information on how this receiver will get customer data. By now, CoreMedia CDP supports HTTP POST with optional basic authentication.`}</li>
        </ul>
      </li>
    </ul>
    <h4>{`Response`}</h4>
    <p><strong parentName="p">{`Status Code`}</strong>{`: 200 OK`}</p>
    <p><strong parentName="p">{`Body`}</strong>{`: Created/Updated receiver group information.`}</p>
    <h4>{`Example Request`}</h4>
    <p><strong parentName="p">{`Request`}</strong>{` `}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "name": "Custom_HTTP",
  "prefix": "custom_http",
  "receiverList": [
    {
      "name": "x",
      "type": {
        "url": "host.docker.internal:3000/x",
        "username": "a",
        "password": "b"
      }
    },
    {
      "name": "y",
      "type": {
        "url": "host.docker.internal:3000/y",
        "username": "a",
        "password": "b"
      }
    }
  ]
}
`}</code></pre>
    <h3>{`Outbound: Set receiver for client and define property mappings`}</h3>
    <p>{`After having Receiver Groups and Receivers confgured on CoreMedia CDP, this API method is used to enable a receiver for a CDP client and set the property mappings from CDP Customer Record into the information the receiver is expecting.`}</p>
    <h4>{`Resource URL`}</h4>
    <p>{`https://{CDP_URL} /settings/{CLIENT_ID}/outbound/{RECEIVER_NAME}`}</p>
    <p><strong parentName="p">{`Method:`}</strong>{` POST`}</p>
    <h4>{`Resource Information`}</h4>
    <p><strong parentName="p">{`Request Format`}</strong>{`: JSON body`}</p>
    <p><strong parentName="p">{`Response format`}</strong>{`: JSON`}</p>
    <p><strong parentName="p">{`Requires Authentication`}</strong>{`: Yes`}</p>
    <h4>{`Response`}</h4>
    <p><strong parentName="p">{`Status Code`}</strong>{`: 200 OK`}</p>
    <p><strong parentName="p">{`Body`}</strong>{`: Created/Updated receiver group information.`}</p>
    <h4>{`Example Request`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "primaryIdentifier": "visitorID",
    "receiverID": 1,
    "propertyMappingList": [
        {
            "propertyName": "nome",
            "mapping": "name37"
        },
        {
            "propertyName": "visitorID",
            "mapping": "name43"
        }
    ]
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      